import React from "react";
import { FaLinkedinIn, FaFacebookF } from 'react-icons/fa';
import { AiOutlineTwitter } from 'react-icons/ai';
import { TfiInstagram } from 'react-icons/tfi';
import { Link } from "react-router-dom";
function TeamSmall(props) {
    return (
        <>
            <div className="w-[80%] sm:w-[50%] mx-auto lg:w-1/3 relative group">
                <img src={props.image} className="" />
                <div className="flex justify-center absolute w-full -bottom-12 transition-all duration-300 group-hover:bottom-28 group-hover:opacity-0">
                    <div className="w-[70%] h-24 bg-white flex flex-col justify-center items-center ">
                        <p className="text-[24px] font-outfit font-bold text-[#14100c]">{props.name}</p>
                        <p className="text-[#91765a] text-[15px] font-outfit">{props.team}</p>
                    </div>
                </div>
                <div className="flex flex-col space-y-3 justify-center items-center absolute after:absolute after:w-full after:h-full after:top-0 after:left-0 after:bg-[rgba(0,0,0,0.5)] bottom-0 w-full h-0 opacity-0 transition-all duration-300 group-hover:h-full group-hover:opacity-100">
                    <div className="flex flex-col justify-center items-center relative z-20">
                        <p className="text-[24px] font-outfit font-bold text-white">{props.name}</p>
                        <p className="text-[#91765a] text-[15px] font-outfit">{props.team}</p>
                    </div>
                    <div className="text-white text-[15px] font-outfit text-center relative z-20 max-w-[80%]">
                        Nulla quis efficitur lacus sulvinar suere ausue in eduis euro vesatien arcuman ontese auctor ac aleuam aretra.
                    </div>
                    <div className="relative z-20 max-w-[80%]">
                        <ul className="flex space-x-2">
                            <li><a href="" className="text-[15px] text-main transition-all duration-300 cursor-pointer hover:text-white"><FaLinkedinIn /></a></li>
                            <li><a href="" className="text-[15px] text-main transition-all duration-300 cursor-pointer hover:text-white"><FaFacebookF /></a></li>
                            <li><a href="" className="text-[15px] text-main transition-all duration-300 cursor-pointer hover:text-white"><AiOutlineTwitter /></a></li>
                            <li><a href="" className="text-[15px] text-main transition-all duration-300 cursor-pointer hover:text-white"><TfiInstagram /></a></li>
                        </ul>
                    </div>
                    <div className="relative z-20 max-w-[80%] mt-6 pt-6">
                        <Link className=" px-[24px] py-[12px] bg-white text-black font-bold font-outfit transition-all duration-300 hover:bg-main hover:text-white">
                            Team Details
                        </Link>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TeamSmall