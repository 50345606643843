import React from "react";


function PriceSmall(props) {
    return (
        <>
            <div>
                <div className="flex justify-between">
                    <div className="font-bold flex-shrink-0 font-outfit text-[14px] sm:text-[17px] text-[#14100c]">{props.title}</div>
                    <div className="Dotted-After  w-[100%] relative after:absolute flex items-center after:w-[96%] after:h-auto after:right-[2%]"></div>
                    <div className="font-bold font-outfit  text-main">{props.price}</div>
                </div>
                <div>
                    <i className="text-[15px] font-outfit text-[#625c56]">{props.description}</i>
                </div>
            </div>
        </>
    )
}
export default PriceSmall