import React from "react";

function OfferSmall(props) {
    return(
        <>
           <div className="sm:w-[80%] mx-auto md:w-1/2 lg:w-1/3 p-2">
                <div className="bg-[#fdfbf8] py-[40px] px-[20px] group cursor-pointer">
                    <div className="flex justify-center">
                        <img src={props.image}  className="opacity-75 transition-all duration-300 Image-Filter group-hover:rotate-[360deg] group-hover:filter-none" />
                    </div>
                    <div className="text-[#14100c] text-[24px] font-bold mt-2 text-center transition-all duration-300 group-hover:text-main">
                        {props.title}
                    </div>
                    <div className="text-[#14100c] text-[15px]  text-center mt-4">
                        {props.description}
                    </div>
                    
                </div>
            </div>     
        </>
    )
}
export default OfferSmall