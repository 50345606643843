import React from "react";
import Slider from "react-slick";
import SmallNews from "./smallNews";
import Sl1 from "../../assets/img/16.jpg";
import Sl2 from "../../assets/img/19.jpg";
import Sl3 from "../../assets/img/8.jpg";
import Sl4 from "../../assets/img/9.jpg";
import Sl5 from "../../assets/img/6.jpg";
import Sl6 from "../../assets/img/4.jpg";
function HomeNews() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows:false,
        responsive: [
            {
              breakpoint: 788,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            
            
          ]
    };
    return (
        <>
            <div className="w-full bg-[#F5EEE7] z-0  relative after:absolute after:w-full after:h-[60%] after:bg-[#14100c] after:left-0 after:top-0 after:-z-20 py-24" id="blog">
                <div className="relative z-50 max-w-6xl  mx-auto max-xl:px-6">
                    <div className='w-full'>
                        <p className="text-center tracking-[2px] uppercase font-bold font-outfit text-main text-[12px]">LATEST NEWS</p>
                        <h1 className="text-center font-outfit font-bold  text-[24px] md:text-[34px] xl:text-[48px] tracking-[1px] m-0 text-white">News & Blog</h1>
                    </div>
                </div>
                <div className="relative mt-12 z-50 max-w-6xl  mx-auto max-xl:px-6 No-Hidden">
                    <Slider {...settings} className="">
                        <div className="pb-12">
                            <SmallNews image={Sl1} manth={'dec'} day={24} title={'wedding'} titleBig = {'Best Tips for Groom Shaving for Your Wedding'} />
                        </div>
                        <div className="pb-12">
                        <SmallNews image={Sl2} manth={'dec'} day={20} title={'blog'} titleBig = {'What We Need to Choose The Fashion Model?'} />
                        </div>
                        <div className="pb-12">
                        <SmallNews image={Sl3} manth={'dec'} day={29} title={'Hair Care'} titleBig = {'Womens Hair Care Routine for Any Hair Type'} />
                        </div>
                        <div className="pb-12">
                        <SmallNews image={Sl4} manth={'dec'} day={27} title={'beard'} titleBig = {'Common Mistakes That Damage Your Beard'} />
                        </div>
                        <div className="pb-12">
                        <SmallNews image={Sl5} manth={'dec'} day={23} title={'Hairstyle'} titleBig = {'5 Most Iconic Mens Hairstyles Of All Times'} />
                        </div>
                        <div className="pb-12">
                        <SmallNews image={Sl6} manth={'dec'} day={21} title={'Haircut'} titleBig = {'What Are The Secrets of The Haircut & Moustache Trim?'} />
                        </div>
                        
                    </Slider>
                </div>
            </div>
        </>
    )
}
export default HomeNews