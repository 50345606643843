import React from 'react';
import Service1Img from '../../assets/img/2.jpg';
import Service2Img from '../../assets/img/1.jpg';
import Service3Img from '../../assets/img/3.jpg';

import { BsScissors } from 'react-icons/bs';
import {GiRazor} from 'react-icons/gi';
import WashImg from '../../assets/img/icons8-hair-washing-sink-64.png';
function HomeService() {
    return (
        <>
            <div id={'Service'} className='w-full bg-[#F5EEE7] py-24'>
                <div className='max-w-6xl mx-auto  max-xl:px-6'>
                    <div className='w-full'>
                        <p className="text-center tracking-[2px] uppercase font-bold font-outfit text-main text-[12px]">WHAT WE'RE OFFERING</p>
                        <h1 className="text-center font-outfit font-bold  text-[24px] md:text-[34px] xl:text-[48px] tracking-[1px] m-0">Barber Services</h1>
                    </div>
                    <div className='flex flex-col md:flex-row max-md:space-y-6 md:space-x-6 mt-12'>
                        <div className='sm:w-[60%] mx-auto md:w-1/3 relative group overflow-hidden z-0 cursor-pointer'>
                            <img src={Service1Img} className='transition-all duration-1000 ease-linear group-hover:scale-110 group-hover:brightness-75' />
                            <div className='bottom-0 left-0  transition-all duration-300 ease-linear w-full h-60 z-50 absolute translate-y-28 group-hover:translate-y-0 px-6'>
                                <div className='flex justify-center text-white text-[40px]'>
                                    <BsScissors />
                                </div>
                                <div
                                    className='font-bold font-outfit text-white mt-2 text-[27px] relative 
                                    text-center after:absolute after:left-[50%] after:-bottom-2 after:h-[1px] after:w-[10%] after:bg-main after:z-50 after:transition-all after:duration-[2s] after:ease-linear group-hover:after:w-[52%] before:absolute before:right-[50%] before:-bottom-2 before:h-[1px] before:w-[10%] before:bg-main before:z-50 before:transition-all before:duration-[2s] before:ease-linear group-hover:before:w-[52%]'>
                                    Hair Cut
                                </div>
                                <div className='text-white font-bold font-outfit text-[15px] mt-10 text-center'>
                                    Hair cut amet ravida haretra nuam the drana miss uctus enec accumsan.
                                </div>
                            </div>
                        </div>
                         <div className='sm:w-[60%] mx-auto md:w-1/3 relative group overflow-hidden z-0 cursor-pointer'>
                            <img src={Service2Img} className='transition-all duration-1000 ease-linear group-hover:scale-110 group-hover:brightness-75' />
                            <div className='bottom-0 left-0  transition-all duration-300 ease-linear w-full h-60 z-50 absolute translate-y-28 group-hover:translate-y-0 px-6'>
                                <div className='flex justify-center text-white text-[40px]'>
                                    <GiRazor />
                                </div>
                                <div
                                    className='font-bold font-outfit text-white mt-2 text-[27px] relative 
                                    text-center after:absolute after:left-[50%] after:-bottom-2 after:h-[1px] after:w-[10%] after:bg-main after:z-50 after:transition-all after:duration-[2s] after:ease-linear group-hover:after:w-[52%] before:absolute before:right-[50%] before:-bottom-2 before:h-[1px] before:w-[10%] before:bg-main before:z-50 before:transition-all before:duration-[2s] before:ease-linear group-hover:before:w-[52%]'>
                                    Beard Trim
                                </div>
                                <div className='text-white font-bold font-outfit text-[15px] mt-10 text-center'>
                                    Hair cut amet ravida haretra nuam the drana miss uctus enec accumsan.
                                </div>
                            </div>
                        </div>
                         <div className='sm:w-[60%] mx-auto md:w-1/3 relative group overflow-hidden z-0 cursor-pointer'>
                            <img src={Service3Img} className='transition-all duration-1000 ease-linear group-hover:scale-110 group-hover:brightness-75' />
                            <div className='bottom-0 left-0  transition-all duration-300 ease-linear w-full h-60 z-50 absolute translate-y-28 lg:translate-y-28 group-hover:translate-y-0 px-6'>
                                <div className='flex justify-center text-white text-[40px]'>
                                    <img src={WashImg} className='w-[40px] h-[40px]' />
                                </div>
                                <div
                                    className='font-bold font-outfit text-white mt-2 text-[27px] relative 
                                    text-center after:absolute after:left-[50%] after:-bottom-2 after:h-[1px] after:w-[10%] after:bg-main after:z-50 after:transition-all after:duration-[2s] after:ease-linear group-hover:after:w-[52%] before:absolute before:right-[50%] before:-bottom-2 before:h-[1px] before:w-[10%] before:bg-main before:z-50 before:transition-all before:duration-[2s] before:ease-linear group-hover:before:w-[52%]'>
                                    Hair Wash
                                </div>
                                <div className='text-white font-bold font-outfit text-[15px] mt-10 text-center'>
                                    Hair cut amet ravida haretra nuam the drana miss uctus enec accumsan.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeService