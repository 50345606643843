import React from "react";
import Modal from 'react-modal';
import { Player } from 'video-react';
import PlayImg from '../../assets/img/play.png'
function HomeVideo() {
    const customStyles = {
        content: {
            // top: '50%',
            // left: '50%',
            // right: 'auto',
            // bottom: 'auto',
            position: "relative",
            // marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
            zIndex: "9999999",
            background: "transparent",
            width: "100%",
            height: "100%",
            opacity: "1",
            transition: "all 1s linear",
            background: "rgba(255,255,255,.5)",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }



    function closeModal() {
        setIsOpen(false);
    }
    return (
        <>
            <div className="w-full  relative py-24 bg-bg-image2 bg-cover bg-no-repeat bg-fixed bg-center before:absolute before:w-full before:h-full before:top-0 before:left-0 before:bg-[rgba(0,0,0,.2)]">
                <div className="text-white text-[24px] sm:text-[40px] xl:text-[60px] font-bold font-outfit text-center relative z-50">
                    Watch Our Barbershop <br/> Promo Video
                </div>
                <div className="md:mt-6 relative z-50">
                    <button onClick={openModal} className={'flex  justify-center items-center mx-auto h-16 w-16 rounded-full bg-white'}>
                        <img src={PlayImg} />
                    </button>
                    <Modal
                        isOpen={modalIsOpen}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel=""
                        className="z-50"
                        id="VideoABS"
                    >
                        <Player className={'pt-0 z-50 relative  sm:w-[800px] sm:h-[500px]'} autoPlay>
                            <button onClick={closeModal} className={'p-4  text-main text-4xl z-50 absolute right-0 -top-20'}>X</button>

                            {/* <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"  /> */}
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8AdddGDMZ1s" title="ASMR BARBER SHOP HAIRCUT TUTORIAL!💈🔥🏴" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Player>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default HomeVideo