import React from "react";
import { TfiInstagram, TfiYoutube } from 'react-icons/tfi';
import { CiTwitter } from 'react-icons/ci';
import { FaFacebookF, FaPinterestP } from 'react-icons/fa';
import DasSmall from "../Components/dasSmall";
function Footer() {
    return (
        <>
            <div id={'contact'} className="w-full bg-[#14100c] pt-20 pb-10">
                <div className="max-w-6xl mx-auto max-xl:px-6 flex flex-wrap md:flex-nowrap md:space-x-12">
                    <div className="max-sm:w-full w-1/2 md:w-1/3">
                        <div className="text-white font-outfit font-bold text-[27px]">
                            Contact
                        </div>
                        <div className="text-[#aba59f] font-outfit text-[15px] mt-3">
                            <p>0665 Broadway NY, New York 10001</p>
                            <p>United States of America</p>
                        </div>
                        <div className="mt-3">
                            <a href="tel:855 100 4444" className="text-[#91765a] font-outfit font-bold text-[27px] transition-all duration-300 hover:text-white">855 100 4444</a>
                        </div>
                        <div className="mt-3">
                            <a href="" className="text-[#aba59f] font-outfit underline text-[15px] transition-all duration-300 hover:text-white">info@barber.com</a>
                        </div>
                        <div className="mt-3">
                            <ul className="flex space-x-3 ">
                                <li><a href="" className="text-[15px] text-[#91765a] transition-all duration-300 hover:text-white"><TfiInstagram /></a></li>
                                <li><a href="" className="text-[15px] text-[#91765a] transition-all duration-300 hover:text-white"><CiTwitter /></a></li>
                                <li><a href="" className="text-[15px] text-[#91765a] transition-all duration-300 hover:text-white"><TfiYoutube /></a></li>
                                <li><a href="" className="text-[15px] text-[#91765a] transition-all duration-300 hover:text-white"><FaFacebookF /></a></li>
                                <li><a href="" className="text-[15px] text-[#91765a] transition-all duration-300 hover:text-white"><FaPinterestP /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="max-sm:w-full w-1/2 md:w-1/3 max-sm:mt-6">
                        <div className="text-white font-outfit font-bold text-[27px]">
                            Work Time
                        </div>
                        <div>
                            <DasSmall title={'Monday'} time={'10:00 - 20:00'} />
                            <DasSmall title={'Tuesday'} time={'10:00 - 20:00'} />
                            <DasSmall title={'Thursday'} time={'10:00 - 20:00'} />
                            <DasSmall title={'Friday'} time={'10:00 - 20:00'} />
                            <DasSmall title={'Saturday'} time={'10:00 - 20:00'} />
                            <DasSmall title={'Weekend'} time={'Closed'} />
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 max-sm:mt-6">
                        <div className="text-white font-outfit font-bold text-[27px]">
                            Subscribe
                        </div>
                        <div className="mt-2 text-[#aba59f] font-outfit text-[15px]">
                            Subscribe to take advantage of our campaigns and gift certificates.
                        </div>
                        <div className="mt-6 flex flex-shrink-0 border-[1px] border-[#91765a]">
                            <input className="w-full bg-transparent focus:outline-none text-[#aba59f] placeholder:text-[#aba59f] p-[16px]" placeholder="Your Email" /> <button className="bg-[#91765a] text-white uppercase font-bold font-outfit py-[14px] px-[20px] text-[12px]">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-[#14100c] py-8">
                <div className="max-w-6xl mx-auto max-xl:px-6 text-[#aba59f] text-[12px] font-outfit">
                    2023 © All rights reserved. Designed by ABOV LLC
                </div>
            </div>
        </>
    )
}
export default Footer
