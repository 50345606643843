import React from "react";
import Team1 from '../../assets/img/b1.jpg'
import Team2 from '../../assets/img/b2.jpg'
import Team3 from '../../assets/img/b3.jpg'
import TeamSmall from "./teamSmall";

function HomeTeam() {
    return (
        <>
            <div className="w-full bg-[#F5EEE7] z-0  relative after:absolute after:w-full after:h-[60%] after:bg-[#14100c] after:left-0 after:top-0 after:-z-20 py-24 ">
                <div className="relative z-50 max-w-6xl  mx-auto max-xl:px-6">
                    <div className='w-full'>
                        <p className="text-center tracking-[2px] uppercase font-bold font-outfit text-main text-[12px]">OUR BARBERS</p>
                        <h1 className="text-center font-outfit font-bold  text-[24px] md:text-[34px] xl:text-[48px] tracking-[1px] m-0 text-white">Hair Stylists</h1>
                    </div>
                    <div className="cursor-pointer flex max-lg:flex-col mt-12 max-lg:space-y-24 lg:space-x-6">
                        <TeamSmall image={Team1} name={'Philip Brown'} team={'Barber'}  />
                        <TeamSmall image={Team2} name={'Stephen Martin'} team={'Barber'}  />
                        <TeamSmall image={Team3} name={'Dennis Dan'} team={'Barber'}  />
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeTeam