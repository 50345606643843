import React from "react";
import {AiOutlineCheck} from 'react-icons/ai';
import {BsScissors} from 'react-icons/bs';
import {TbRazorElectric} from 'react-icons/tb';
import {GiRazor} from 'react-icons/gi';
import About2Img from "../../assets/img/about2.jpg";
import About1Img from "../../assets/img/about.jpg";
import HomeYear from "./homeYear";
function HomeAbout() {
    return (
        <>
            <div className="w-full  bg-[#F5EEE7] py-24" id="About">
                <div className="max-w-6xl mx-auto flex flex-col md:flex-row max-xl:px-6">
                    <div className="w-full md:w-1/2">
                        <p className="tracking-[2px] uppercase font-bold font-outfit text-main text-[12px]">SINCE 2006</p>
                        <h1 className="font-outfit font-bold  sm:text-[20px] md:text-[34px] xl:text-[48px] tracking-[1px] m-0">Perukar Barber Shop</h1>
                        <p className="font-outfit text-[15px] text-[#625c56]">
                            Come experience a unique and edgy barbershop for all your hair and beard needs. ravida haretra nuam enim mi obortis eset uctus enec accumsan eu justo alisuame amet auctor orci donec vitae vehicula risus.
                        </p>
                        <p className="font-outfit text-[15px] text-[#625c56] mt-6">
                            Barber utate ons amet ravida haretra nuam the duru miss uctus the drana accumsan justo aliquam sit amet auctor orci done vitae risus duise nisan sapien silver on the accumsan id mauris apien.
                        </p>
                        <ul className="mt-6">
                            <li className="flex items-center flex-row mt-2 space-x-4 font-outfit text-[15px] text-[#625c56]"><AiOutlineCheck className="text-[#91765a]" /> <span>We're professional and certified barbers</span></li>
                            <li className="flex items-center flex-row mt-2 space-x-4 font-outfit text-[15px] text-[#625c56]"><AiOutlineCheck className="text-[#91765a]" /> <span>We use quality products to make you look perfect</span></li>
                            <li className="flex items-center flex-row mt-2 space-x-4 font-outfit text-[15px] text-[#625c56]"><AiOutlineCheck className="text-[#91765a]" /> <span>We care about our customers satisfaction</span></li>
                        </ul>
                    </div>
                    <div className="w-full max-sm:flex-col md:w-1/2 flex max-md:justify-center">
                        <div className="mt-16 p-4">
                            <img src={About2Img} className="w-full h-full sm:w-[260px] sm:h-[335px]" />
                        </div>
                        <div className="p-4">
                            <img src={About1Img} className="sm:w-[260px] sm:h-[335px]" />
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl mx-auto flex mt-12 md:mt-36 flex-col md:flex-row max-xl:px-6">
                    <div className="w-full sm:w-[80%] max-md:mt-4 max-md:mx-auto md:w-1/3 flex">
                        <div className="w-[16%]  flex pt-2"><BsScissors className="text-main text-[26px] sm:text-[50px]" /></div>
                        <div className="w-[84%] px-4">
                            <h1 className="font-bold font-outfit text-[18px] sm:text-[24px] text-[#14100c]">Cuts</h1>
                            <p className="mt-2 text-justify text-[#625c56] font-outfit text-[12px] sm:text-[16px]">Cuts ut nisl quam nestibulum drana odio elementum sceisue the can golden varius the dis monte.</p>
                        </div>
                    </div>
                    <div className="w-full sm:w-[80%] max-md:mt-4 max-md:mx-auto md:w-1/3 flex">
                        <div className="w-[16%]  flex pt-2"><TbRazorElectric className="text-main text-[26px] sm:text-[50px]" /></div>
                        <div className="w-[84%] px-4">
                            <h1 className="font-bold font-outfit text-[18px] sm:text-[24px] text-[#14100c]">Fades</h1>
                            <p className="mt-2 text-justify text-[#625c56] font-outfit text-[12px] sm:text-[16px]">Fades ut nisl quam nestibulum drana odio elementum sceisue the can golden varius the dis monte.</p>
                        </div>
                    </div>
                    <div className="w-full sm:w-[80%] max-md:mt-4 max-md:mx-auto md:w-1/3 flex">
                        <div className="w-[16%]  flex pt-2"><GiRazor className="text-main text-[26px] sm:text-[50px]" /></div>
                        <div className="w-[84%] px-4">
                            <h1 className="font-bold font-outfit text-[18px] sm:text-[24px] text-[#14100c]">Shaves</h1>
                            <p className="mt-2 text-justify text-[#625c56] font-outfit text-[12px] sm:text-[16px]">Shaves ut nisl quam nestibulum drana odio elementum sceisue the can golden varius the dis monte.</p>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default HomeAbout