import React from "react";
import { Link } from "react-scroll";
import { BsArrowDown } from 'react-icons/bs';
function HomeFirst() {
    return (
        <>
            <div id="First_Home" className="w-full h-[100vh] bg-hero-pattern relative bg-cover after:absolute after:w-full after:h-full after:top-0 after:left-0 after:bg-[rgba(0,0,0,.5)] flex  justify-center after:z-0 items-center">
                <div className="relative text-white max-w-xl  z-[1]">
                    <p className="font-outfit text-[12px] uppercase font-bold text-center tracking-[2px]">STAY SHARP, LOOK GOOD</p>
                    <h1 className="font-outfit font-bold mt-2 sm:mt-6 sm:text-[30px] md:text-[40px] xl:text-[60px] tracking-[1px] text-center md:leading-[50px] xl:leading-[70px]">NYC'S FAVOURITE
                        BARBER SHOP.</h1>
                    <p className="font-outfit text-[12px] mt-2 sm:mt-6 uppercase font-bold text-center tracking-[2px]">BROADWAY ST, NYC. APPOINTMENT: 855 100 4444</p>
                    <div className="mt-6 flex justify-center">
                        <Link to={''} className="px-[25px] py-[15px] bg-white text-black font-bold font-outfit uppercase text-[12px] cursor-pointer transition-all duration-300 hover:bg-main hover:text-white">Book Appointment</Link>
                    </div>
                </div>
                <Link to="About" spy={true} smooth={true} offset={0} duration={500} className="animate-bounce w-12 h-12 text-white flex justify-center transition-all duration-500 items-center cursor-pointer   rounded-full border-[1px] border-white shadow-2xl absolute bottom-4 z-50 hover:text-main hover:border-main">
                    <div class="">
                        <BsArrowDown />
                    </div>
                </Link>
            </div>
        </>
    )
}
export default HomeFirst