import React from 'react';
import Slider from "react-slick";
import StarImg from "../../assets/img/start.png";
import { AiFillStar } from "react-icons/ai";
import OpImg1 from "../../assets/img/3 (1).jpg";
import OpImg2 from "../../assets/img/2 (1).jpg";
import OpImg3 from "../../assets/img/3 (1).jpg";
function HomeOpinion() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,
        dots:false
    };
    return (
        <>
            <div className='bg-bg-image relative after:absolute after:w-full after:h-full after:top-0 after:left-0 after:bg-[rgba(0,0,0,.3)] after:z-0 py-36 bg-cover bg-fixed'>
                <div className='max-w-2xl mx-auto relative z-50'>
                    <Slider {...settings}>
                        <div className='px-6'>
                            <div className='relative z-20 flex flex-col space-y-4'>
                                <div className='flex justify-center space-x-2'>
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />

                                </div>
                                <div className='text-white font-outfit text-[15px] text-center'>
                                    Lorem dapibus asue metus the nec feusiate eraten miss hendreri net ve ante the lemon sanleo nectan feugiat erat hendrerit necuis ve ante viventa miss sapien silver on the duiman lorem ipsum amet silver miss rana duru at finibus viverra neca the sene on satien.
                                </div>
                                <div className='flex flex-col items-center'>
                                    <div className='w-fit p-[2px] rounded-full border-[2px] border-[rgba(255,255,255,0.2)]'>
                                        <img src={OpImg1} className='w-[54px] h-[54px] rounded-full' />
                                    </div>
                                    <div>
                                        <p className='text-center font-bold text-white font-outfit text-[12px]'>Daniel Martin</p>
                                        <p className='text-center text-white font-outfit text-[12px]'>Customer review</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='px-6'>
                            <div className='relative z-20 flex flex-col space-y-4'>
                                <div className='flex justify-center space-x-2'>
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />

                                </div>
                                <div className='text-white font-outfit text-[15px] text-center'>
                                    Lorem dapibus asue metus the nec feusiate eraten miss hendreri net ve ante the lemon sanleo nectan feugiat erat hendrerit necuis ve ante viventa miss sapien silver on the duiman lorem ipsum amet silver miss rana duru at finibus viverra neca the sene on satien.
                                </div>
                                <div className='flex flex-col items-center'>
                                    <div className='w-fit p-[2px] rounded-full border-[2px] border-[rgba(255,255,255,0.2)]'>
                                        <img src={OpImg2} className='w-[54px] h-[54px] rounded-full' />
                                    </div>
                                    <div>
                                        <p className='text-center font-bold text-white font-outfit text-[12px]'>Andreas Brown</p>
                                        <p className='text-center text-white font-outfit text-[12px]'>Customer review</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='px-6'>
                            <div className='relative z-20 flex flex-col space-y-4'>
                                <div className='flex justify-center space-x-2'>
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />
                                    <AiFillStar className='text-[14px] text-[#ffd27d]' />

                                </div>
                                <div className='text-white font-outfit text-[15px] text-center'>
                                    Lorem dapibus asue metus the nec feusiate eraten miss hendreri net ve ante the lemon sanleo nectan feugiat erat hendrerit necuis ve ante viventa miss sapien silver on the duiman lorem ipsum amet silver miss rana duru at finibus viverra neca the sene on satien.
                                </div>
                                <div className='flex flex-col items-center'>
                                    <div className='w-fit p-[2px] rounded-full border-[2px] border-[rgba(255,255,255,0.2)]'>
                                        <img src={OpImg3} className='w-[54px] h-[54px] rounded-full' />
                                    </div>
                                    <div>
                                        <p className='text-center font-bold text-white font-outfit text-[12px]'>Emily White</p>
                                        <p className='text-center text-white font-outfit text-[12px]'>Customer review</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </Slider>
                </div>
            </div>
        </>
    )
}
export default HomeOpinion