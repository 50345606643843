import React from "react";

function SmallNews(props) {
    return (
        <>
            <div className="relative group overflow-visible cursor-pointer p-0 sm:p-6  h-fit">
                <div className="overflow-hidden ">
                    <img src={props.image} className="transition-all duration-500 group-hover:scale-110" />
                </div>
                <div className="absolute   pt-[10px] pb-[5px] px-[10px] top-2 left-2 sm:top-[40px] sm:left-[44px] bg-main text-white font-bold font-outfit">
                    <p className="uppercase text-[12px] leading-3">{props.manth}</p>
                    <p className="uppercase text-[24px] leading-8">{props.day}</p>
                </div>
                <div className="flex w-full  -bottom-12  absolute transition-all duration-500 group-hover:bottom-1 sm:group-hover:bottom-12">
                    <div className="h-[90px] sm:h-[132px] w-[80%] left-[5%] xl:h-[152px] relative  bg-white p-2 sm:p-[30px]">
                        <p className="text-[12px] uppercase font-outfit font-bold text-main">{props.title}</p>
                        <p className="text-[#14100c] text-[14px] sm:text-[16px] xl:text-[24px]   font-outfit font-bold">{props.titleBig}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SmallNews