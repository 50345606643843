import React from "react";
import PriceSmall from "./priceSmall";

function HomePrice() {
    return (
        <>

            <div id="Pricing" className="bg-[#F5EEE7] py-24">
                <div className='max-w-6xl mx-auto  max-xl:px-6'>
                    <div className='w-full'>
                        <p className="text-center tracking-[2px] uppercase font-bold font-outfit text-main text-[12px]">PRICING PLAN</p>
                        <h1 className="text-center font-outfit font-bold  text-[24px] md:text-[34px] xl:text-[48px] tracking-[1px] m-0">Barber Pricing</h1>
                    </div>
                    <div className="mt-12 flex max-md:flex-col md:space-x-6">  
                        <div className="w-full md:w-1/2 px-2  space-y-10">
                           <PriceSmall title={'Wash and Cut'} price={'$20'} description={'Lorem nestibulum sceisue can varius the dis monte.'} />
                           <PriceSmall title={'Head Shave with Razor'} price={'$30'} description={'Lorem nestibulum sceisue can varius the dis monte.'} />
                           <PriceSmall title={'Long Hair'} price={'$45'} description={'Lorem nestibulum sceisue can varius the dis monte.'} />
                           <PriceSmall title={'Children Wash & Cut'} price={'$25'} description={'Lorem nestibulum sceisue can varius the dis monte.'} />
                        </div>
                        <div className="w-full md:w-1/2 px-2 max-md:mt-6 space-y-10">
                           <PriceSmall title={'Wash and Style'} price={'$10'} description={'Lorem nestibulum sceisue can varius the dis monte.'} />
                           <PriceSmall title={'Short Beard Tidy'} price={'$15'} description={'Lorem nestibulum sceisue can varius the dis monte.'} />
                           <PriceSmall title={'Large beard trim'} price={'$25'} description={'Lorem nestibulum sceisue can varius the dis monte.'} />
                           <PriceSmall title={'Luxury Full Service'} price={'$60'} description={'Lorem nestibulum sceisue can varius the dis monte.'} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomePrice