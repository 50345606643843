import React from "react";
import OfferSmall from "./offerSmall";
import BrashImg from '../../assets/img/brush.png';
import Show1 from "../../assets/img/straight-razor.png";
import Beard1 from "../../assets/img/beard-trimming.png";
import Chair from "../../assets/img/chair.png";
import Scissors from "../../assets/img/scissors (1).png";
import Anti from "../../assets/img/antiseptic.png";
function HomeOffer() {
    return (
        <>
            <div className="w-full bg-[#f5eee7] py-24">
                <div className="relative z-50 max-w-6xl  mx-auto max-xl:px-6">
                    <div className='w-full'>
                        <p className="text-center tracking-[2px] uppercase font-bold font-outfit text-main text-[12px]">OUR SERVICES</p>
                        <h1 className="text-center font-outfit font-bold  text-[24px] md:text-[34px] xl:text-[48px] tracking-[1px] m-0 text-[#14100c]">We Also Offer</h1>
                    </div>
                    <div className="w-full flex flex-wrap">
                        <OfferSmall image={Show1} title={'Moustache Trim'} description={'Lorem vulputate massa ons amet ravida haretra nuam the drana miss uctus enec accumsan aliquam sit sapien.'} />
                        <OfferSmall image={BrashImg} title={'Face Shave'} description={'Lorem vulputate massa ons amet ravida haretra nuam the drana miss uctus enec accumsan aliquam sit sapien.'} />
                        <OfferSmall image={Beard1} title={'Beard Trim'} description={'Lorem vulputate massa ons amet ravida haretra nuam the drana miss uctus enec accumsan aliquam sit sapien.'} />
                        <OfferSmall image={Chair} title={'Haircut'} description={'Lorem vulputate massa ons amet ravida haretra nuam the drana miss uctus enec accumsan aliquam sit sapien.'} />
                        <OfferSmall image={Scissors} title={'Clipper Cut'} description={'Lorem vulputate massa ons amet ravida haretra nuam the drana miss uctus enec accumsan aliquam sit sapien.'} />
                        <OfferSmall image={Anti} title={'Facial & Massage'} description={'Lorem vulputate massa ons amet ravida haretra nuam the drana miss uctus enec accumsan aliquam sit sapien.'} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomeOffer