import React from "react";

function DasSmall(props) {
    return(
        <>
             <div>
                <div className="flex justify-between mt-2">
                    <div className="flex-shrink-0 font-outfit text-[12px] xl:text-[15px] text-[#aba59f]">{props.title}</div>
                    <div className="Dotted-After  w-[50%] relative after:absolute flex items-center after:w-[96%] after:h-auto after:right-[2%]"></div>
                    <div className="font-outfit  text-[#aba59f] text-[12px] xl:text-[15px]">{props.time}</div>
                </div>
            </div>
        </>
    )
}

export default DasSmall