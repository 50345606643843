import React from "react";
import BarbImg from "../../assets/img/barber.jpg";
import BarbImg2 from "../../assets/img/kids.jpg";
import BarbImg3 from "../../assets/img/b3.jpg";
import { Link } from "react-router-dom";
function HomeFeatures() {
    return (
        <>
            <div className="w-full bg-[#F5EEE7]  relative after:absolute after:w-full after:h-[60%] after:bg-[#14100c] after:left-0 after:top-0 after:z-0 py-24 ">
                <div className="relative z-[10] max-w-6xl  mx-auto max-xl:px-6">
                    <div className='w-full'>
                        <p className="text-center tracking-[2px] uppercase font-bold font-outfit text-main text-[12px]">FIRS-CLASS</p>
                        <h1 className="text-center font-outfit font-bold  text-[24px] md:text-[34px] xl:text-[48px] tracking-[1px] m-0 text-white">Our Features</h1>
                    </div>
                    <div className="flex max-lg:flex-col">
                        <div className="w-[90%] cursor-pointer max-[480px]:mx-0 mx-auto  md:w-1/2 lg:w-1/3 ">
                            <div className="cards-wrapper ">
                                <div class="card-container">
                                    <div className="card">
                                        <div className="card-contents card-front">
                                            <img src={BarbImg} className="" />
                                            <div className="card-depth absolute bottom-12 text-white font-bold font-outfit text-[27px]">Groom's Shave</div>
                                        </div>
                                        <div className="card-contents card-back p-[48px] ">
                                            <h3 className="card-depth font-bold font-outfit text-[28px] text-[#FFFFFF]">Groom's Shave</h3>
                                            <div className="card-depth text-[#FFFFFF] font-outfit text-[15px]">
                                                Lorem nisl miss nestibulum nec odio duru the aucan ula orci varius natoque enatau manis dis arturient monte.
                                            </div>
                                            <div className="mt-6 card-depth">
                                                <Link className=" px-[24px] py-[12px] bg-white text-black font-bold font-outfit rounded-md transition-all duration-300 hover:bg-black hover:text-white">	Appointment	</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[90%] cursor-pointer max-[480px]:mx-0 mx-auto  md:w-1/2 lg:w-1/3 ">
                            <div className="cards-wrapper ">
                                <div class="card-container">
                                    <div className="card">
                                        <div className="card-contents card-front">
                                            <img src={BarbImg2} className="" />
                                            <div className="card-depth absolute bottom-12 text-white font-bold font-outfit text-[27px]">Kids Cuts</div>
                                        </div>
                                        <div className="card-contents card-back p-[48px] ">
                                            <h3 className="card-depth font-bold font-outfit text-[28px] text-[#FFFFFF]">Kids Cuts</h3>
                                            <div className="card-depth text-[#FFFFFF] font-outfit text-[15px]">
                                                Lorem nisl miss nestibulum nec odio duru the aucan ula orci varius natoque enatau manis dis arturient monte.
                                            </div>
                                            <div className="mt-6 card-depth">
                                                <Link className=" px-[24px] py-[12px] bg-white text-black font-bold font-outfit rounded-md transition-all duration-300 hover:bg-black hover:text-white">	Appointment	</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[90%] cursor-pointer max-[480px]:mx-0 mx-auto  md:w-1/2 lg:w-1/3 ">
                            <div className="cards-wrapper ">
                                <div class="card-container">
                                    <div className="card">
                                        <div className="card-contents card-front">
                                            <img src={BarbImg3} className="" />
                                            <div className="card-depth absolute bottom-12 text-white font-bold font-outfit text-[27px]">Creative Barbers</div>
                                        </div>
                                        <div className="card-contents card-back p-[48px] ">
                                            <h3 className="card-depth font-bold font-outfit text-[28px] text-[#FFFFFF]">Creative Barbers</h3>
                                            <div className="card-depth text-[#FFFFFF] font-outfit text-[15px]">
                                                Lorem nisl miss nestibulum nec odio duru the aucan ula orci varius natoque enatau manis dis arturient monte.
                                            </div>
                                            <div className="mt-6 card-depth">
                                                <Link className=" px-[24px] py-[12px] bg-white text-black font-bold font-outfit rounded-md transition-all duration-300 hover:bg-black hover:text-white">	Appointment	</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomeFeatures