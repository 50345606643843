import React from "react";
import { AiFillStar } from 'react-icons/ai';
import Show1 from "../../assets/img/razor1.png";
import "../../assets/js/script";
function HomeContact() {
    return (
        <>
            <div className="w-full pt-24 bg-bg-image3 bg-cover bg-center relative before:absolute before:w-full before:h-full before:top-0 before:left-0 before:bg-[rgba(0,0,0,.4)]">
                <div className="max-w-6xl max-xl:px-6 mx-auto relative z-20 flex max-md:flex-col">
                    <div className="md:w-1/2 flex flex-col justify-center space-y-6">
                        <div>
                            <ul className="flex space-x-1">
                                <li className="text-[#ffd27d]"><AiFillStar /></li>
                                <li className="text-[#ffd27d]"><AiFillStar /></li>
                                <li className="text-[#ffd27d]"><AiFillStar /></li>
                                <li className="text-[#ffd27d]"><AiFillStar /></li>
                                <li className="text-[#ffd27d]"><AiFillStar /></li>
                            </ul>
                        </div>
                        <div className="text-white sm:text-[27px] font-bold font-outfit sm:pr-24">
                            We Are Best Barbers & Hair Cutting Salon at NYC.
                        </div>
                        <div className="flex space-x-6">
                            <div>
                                <img src={Show1} className="-rotate-12 Image-Filter w-[60px] h-[60px]" />
                            </div>
                            <div>
                                <p className="uppercase text-[12px] font-outfit font-bold text-main tracking-[2px]">APPOINTMENT</p>
                                <p className="uppercase sm:text-[27px] font-outfit font-bold text-white tracking-[2px]">855 100 4444</p>
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2 ">
                        <div className=" max-w-md bg-[#f5eee7] pt-[25px] pb-[25px] sm:pt-[45px] sm:pb-[60px] px-[10px] sm:px-[30px] relative -bottom-16">
                            <h1 className="text-[#14100c] sm:text-[27px] font-outfit font-bold text-center">Make An Appointment</h1>
                            <div className="">
                                <form>
                                    <div className="flex flex-col sm:flex-row justify-between sm:space-x-6">
                                        <div className="sm:w-1/2 mt-4">
                                            <input type="text" name="name" placeholder="Name" className="text-[15px] text-[#14100c] placeholder:text-[#14100c] bg-white p-[15px] focus:outline-none w-[100%]" />
                                        </div>
                                        <div className="sm:w-1/2 mt-4">
                                            <input type="text" name="phone" placeholder="Phone" className="text-[15px] text-[#14100c] placeholder:text-[#14100c] bg-white p-[15px] focus:outline-none w-[100%]" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row justify-between sm:space-x-6 sm:mt-6">
                                        <div className="sm:w-1/2 mt-4">
                                            <input type="date" name="date" placeholder="Date" className="text-[15px] text-[#14100c] placeholder:text-[#14100c] bg-white p-[15px] focus:outline-none w-[100%]" />
                                        </div>
                                        <div className="sm:w-1/2 mt-4">
                                            <input type="time" name="phone" placeholder="Phone" className="text-[15px] text-[#14100c] placeholder:text-[#14100c] bg-white p-[15px] focus:outline-none w-[100%]" />
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-between space-x-6 mt-6">
                                        <div className="w-full">
                                            <textarea className="w-full focus:outline-none p-[15px]" placeholder="Specify the specialist and the type of service"></textarea>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <button type="submit" className="text-[12px] font-bold uppercase tracking-[2px] font-outfit w-full bg-main text-white transition-all duration-300 hover:bg-[#14100c] hover:text-white h-[52px]">Make Appointment</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            <div className="w-full py-12 bg-[#91765a]"></div>
        </>
    )
}

export default HomeContact