import $ from 'jquery';

$('#Navbar').css("z-index" , "999999999999999999")
$('#VideoABS').css("z-index" , "999999999999999999")
$('.ankap').css('background' , 'white')
$('.ankap').css('font-size' , '30px' ,'important')
$(document).ready(function() {

    $(window).scroll(function() {
  
      var scroll = $(window).scrollTop();
  

        if(scroll > 5){
            $('#Navbar').addClass('animate-info1')
        }else{
            $('#Navbar').removeClass('animate-info1')
        }
    });
  });