import React from "react";
import HomeFirst from "./Components/homeFirst";
import HomeAbout from "./Components/homeAbout";
import HomeYear from "./Components/homeYear";
import HomeService from "./Components/homeService";
import HomeOpinion from "./Components/homeOpinion";
import HomeFeatures from "./Components/homeFeatures";
import HomeVideo from "./Components/homeVideo";
import HomePrice from "./Components/homePrice";
import HomeTeam from "./Components/homeTeam";
import HomeOffer from "./Components/homeOffer";
import HomeNews from "./Components/homeNews";
import HomeContact from "./Components/homeContact";

function Home() {
    return(
        <>
          <HomeFirst />
          <HomeAbout />
          <HomeYear />
          <HomeService />
          <HomeOpinion />
          <HomeFeatures />
          <HomeVideo />
          <HomePrice />
          <HomeTeam />
          <HomeOffer />
          <HomeNews />
          <HomeContact />
        </>
    )
}

export default Home