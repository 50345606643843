import React from "react";
import About3Img from "../../assets/img/about3.jpg";
import SignImg from "../../assets/img/signature.svg";
function HomeYear() {
    return (
        <>
            <div className="bg-[#14100c] py-24">
                <div className="max-w-6xl mx-auto flex max-md:flex-col max-xl:px-6">
                    <div className="md:w-[40%]">
                        <img src={About3Img} />
                    </div>
                    <div className="max-md:mt-6 md:w-[60%] max-md:p-0 px-6 flex flex-col justify-center space-y-4">
                        <p className="tracking-[2px] uppercase font-bold font-outfit text-main text-[12px]">SINCE 2006</p>
                        <h1 className="font-outfit font-bold text-white sm:text-[20px] md:text-[34px] xl:text-[48px] tracking-[1px] m-0 xl:leading-[50px]">Making people look awesome since 2006</h1>
                        <p className="font-outfit text-[15px] text-[#aba59f]">
                            Come experience a unique and edgy barbershop for all your hair and beard needs. ravida haretra nuam enim mi obortis eset uctus enec accumsan eu justo alisuame amet auctor orci donec vitae vehicula risus.
                        </p>
                        <div className="flex space-x-4" >
                            <div className="">
                                <img src={SignImg} className="w-[70px] h-[51px]" />
                            </div>
                            <div>
                                <p className="font-outfit text-[15px] text-[#aba59f]">Barber, Founder</p>
                                <p className="font-outfit text-[15px] text-[#91765a]">Harold Brown</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeYear